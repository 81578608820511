<!-- eslint-disable max-len -->
<template>
	<div>
		<template v-if="loadingFaq">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Faqs</h3>
				</div>
				<div class="d-flex">
					<button
						class="btn btn__primary"
						type="button"
						name="button"
						data-toggle="modal"
						data-target="#faqModal"
						id="addAFaq"
					>
						<span class="btn__icon">
							<svg width="24" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8 5H5V2H3v3H0v2h3v3h2V7h3V5zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 0 18 0c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 0 13 0c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16C20.45 8.89 21 9.82 21 11v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 8c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
									fill="#fff"
								/>
							</svg>
						</span>
						Add Faq
					</button>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<p>#</p>
						<p>Title</p>
						<p>Delete</p>
						<p>Action</p>
					</div>
					<div class="contacts__body">
						<template v-if="faqs.total > 0">
							<FaqItem
								v-for="faq in faqs.data"
								:key="faq.id"
								:faqData="faq"
								@deleteButton="deleteRequestMethod"
							/>
						</template>
						<template v-else-if="faqs.total === 0">
							<p class="mt-5 pl-5">No Faq found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Faqs, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
			<AddFaq @addButton="addRequestMethod" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import AddFaq from './AddFaq';
import Pagination from '../../components/Pagination';
import FaqItem from './FaqItem';

export default {
	name: 'Faqs',
	components: {
		Loader,
		AddFaq,
		Pagination,
		FaqItem
	},
	data() {
		return {
			loadingFaq: true,
			offset: 0,
			limit: 10,
			baseUrl: 'mutual-funds',
			total: 0,
			faqStatusParam: 1,
			statusList: [1, 0],
			item: '',
			childDeleteRequestEvent: '',
			childAddRequestEvent: ''
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
		this.fetchAllFaqs([this.limit, this.offset, this.faqStatusParam]);
		this.total = this.faqs.total;
	},
	watch: {
		status(value) {
			if (value === 'success') {
				if (this.childDeleteRequestEvent === 'deleteFaqClicked') {
					this.fetchAllFaqs([this.limit, this.offset, this.faqStatusParam]);
					this.$router.push('/mutual-funds');
					this.childDeleteRequestEvent = '';
				}
				if (this.childAddRequestEvent === 'addFaqClicked') {
					this.fetchAllFaqs([this.limit, this.offset, this.faqStatusParam]);
					this.childAddRequestEvent = '';
				}
				this.loading = false;
				this.loadingFaq = false;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllFaqs([this.limit, this.offset, this.faqStatusParam]);
			this.total = this.totalFaqs;
		}
	},
	computed: {
		...mapGetters({
			faqs: 'getFaqs',
			status: 'getStatus',
			totalFaqs: 'getTotalFaqs'
		})
	},
	methods: {
		...mapActions(['fetchAllFaqs']),
		selectStatus() {
			this.fetchAllFaqs([this.limit, this.offset]);
		},
		deleteRequestMethod(value) {
			this.childDeleteRequestEvent = value;
		},

		addRequestMethod(value) {
			this.childAddRequestEvent = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 60px 400px 1fr 150px !important;
}
.moveLeft {
	margin-left: -70px;
}
</style>
