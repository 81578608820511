import Joi from 'joi';

const faqs = {
	create: {
		title: Joi.string()
			.min(3)
			.required(),
		content: Joi.required()
	}
};

export default faqs;
