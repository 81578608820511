<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<p>{{ faq.title }}</p>
			<p>
				<button class="btn-danger btn-sm" type="button" data-toggle="modal" data-target="#confirmDeleteModal">
					Delete
				</button>
			</p>
			<p>
				<router-link
					:to="{
						name: 'faq-details',
						params: {
							id: faq.id
						}
					}"
					id="faqDetail"
					class="btn btn__primary"
				>
					View Detail
				</router-link>
			</p>
		</div>
		<ConfirmDeleteModal :faqId="faq.id" />
	</div>
</template>

<script>
import ConfirmDeleteModal from './ConfirmDeleteModal';

export default {
	name: 'faqItem',
	props: {
		faqData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			faq: this.faqData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		isActive() {
			return this.faq.active === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		}
	},
	components: {
		ConfirmDeleteModal
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 60px 400px 1fr 150px !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
