<template>
	<div
		id="confirmDeleteModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Delete Faq</h3>
					<button type="button" class="close" data-dismiss="modal" aria-label="close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body modal__body text-center">
					<p>Are you Sure you want to delete this Faq?</p>
					<div>
						<button class="btn btn--md" type="button" data-dismiss="modal" aria-label="close" name="button">
							Cancel
						</button>
						<button
							class="btn btn--md btn__primary"
							type="button"
							data-dismiss="modal"
							aria-label="close"
							@click="deleteAFaq"
						>
							Proceed
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'ConfirmDeleteModal',
	props: {
		faqId: Number
	},
	methods: {
		...mapActions(['deleteFaqs']),
		deleteAFaq() {
			this.deleteFaqs(this.faqId);
			this.deleteEventFunc();
		},
		deleteEventFunc() {
			this.$emit('deleteButton', 'deleteFaqClicked');
		}
	}
};
</script>

<style lang="scss" scoped>
select {
	height: 50px;
}
</style>
