<!--eslint-disable-->
<template>
	<div
		id="faqModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Add Faq</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="addFaq">
						<div class="form-row">
							<div class="form-group col-md-12">
								<label class="modal__label" for="title">Title</label>
								<input
									:class="[{ 'is-invalid': errors.title }]"
									@keydown="clearErrors($event.target.title)"
									v-model="itemData.title"
									name="title"
									value=""
									type="text"
									class="form-control"
									id="title"
									placeholder="e.g ABC Capital"
								/>
								<div class="invalid-feedback">
									{{ errors.title }}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-12">
								<label class="modal__label" for="content">Content</label>
								<ckeditor
									:editor="editor"
									v-model="itemData.content"
									:config="editorConfig"
								></ckeditor>
								<div class="invalid-feedback">
									{{ errors.content }}
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer modal__footer">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						@click.prevent="addFaq"
						class="btn btn--md btn__primary"
						type="button"
						v-bind="{ disabled: loading }"
						name="button"
						id="addFaq"
					>
						Add Faq
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import form from '../../mixins/form';
import faqs from '../../utils/validations/faqs';

export default {
	name: 'AddFaq',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: false,
			editor: ClassicEditor,
			editorConfig: {
				toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList'],
				heading: {
					options: [
						{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
						{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
						{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
						{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
						{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
						{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
						{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
					]
				}
			}
		};
	},
	computed: {
		...mapGetters(['getStatus'])
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.clearFormField();
				this.clearErrors();
			}
		}
	},
	methods: {
		...mapActions(['createFaq']),
		addFaq() {
			this.validate(this.itemData, faqs.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}

			/* eslint-disable-next-line */
			const { returns, fund_size, fund_minimum_holding_period, fund_management_fee, ...rest } = this.itemData;

			const payload = {
				...rest,
				returns: parseFloat(returns),
				fund_size: parseFloat(fund_size),
				fund_minimum_holding_period: parseFloat(fund_minimum_holding_period),
				fund_management_fee: parseFloat(fund_management_fee)
			};

			this.createFaq(payload);
			this.deleteEventFunc();
			return true;
		},
		deleteEventFunc() {
			this.$emit('addButton', 'addFaqClicked');
		}
	}
};
</script>

<style lang="scss" scoped>
select {
	height: 50px;
}
</style>
